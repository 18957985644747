import { EmptyComponent } from "processhub-components-lib";

const EmptyPage = () => {
  return (
    <EmptyComponent>
      <a
        href="https://app.processhub.ai/"
        rel="noopener noreferrer"
        className="btn"
      >
        Go to Saudi Map
      </a>
    </EmptyComponent>
  );
};

export default EmptyPage;
