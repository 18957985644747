/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

function subtractHours(date: any) {
  date.setHours(date.getHours() - 720);
  return date;
}

const dateNow = new Date();
const dateTime = subtractHours(dateNow);

export const getAllExpressions = () => {
  return energyApi.get(`/ExpMeta/GetAllExpressions${extractedPath}`);
};

export const getExpressionById = (id: any) => {
  return energyApi.get(`/ExpMeta/GetExpression${extractedPath}?id=${id}`);
};

export const getExpressionData = (
  ids: any,
  dateTime?: string
) => {
  let url = `Dashboard/GetExpData${extractedPath}`;
  dateTime ? (url += `?dateTime=${dateTime}`) : null;
  return energyApi.post(url, ids);
};

export const createExpression = (expression: any) => {
  return energyApi.post(`/ExpMeta/CreateExpression${extractedPath}`, expression);
};

export const getExpressionByUnit = (id: any) => {
  return energyApi.get(`/ExpMeta/GetExpressionByUnit${extractedPath}?id=${id}`);
};

export const getExpressionByEquipment = (id: any) => {
  return energyApi.get(`/ExpMeta/GetExpressionByEquipment${extractedPath}?id=${id}`);
};

export const updateExpression = (expression: any) => {
  return energyApi.post(`/ExpMeta/UpdateExpression${extractedPath}`, expression);
};

export const deleteExpression = (id: any) => {
  return energyApi.get(`/ExpMeta/DeleteExpression${extractedPath}?id=${id}`);
};

export const validateExpression = (expression: any) => {
  return energyApi.post(`/ExpMeta/ValidateExpression${extractedPath}`, expression);
};
