import { updateScene } from "api/unity";
import { SmallLoader } from "processhub-components-lib";
import useScene from "hooks/useScene";
import { useEffect, useState } from "react";
import { FiX } from "react-icons/fi";
import classNames from "utilities/ClassNames";

const AddSceneForm: any = ({ closeHandler, pauseUnity }: any) => {
  const { EditSceneFormData, GetAllScenes } = useScene();
  const [loading, setLoading] = useState(false);
  const [submittingForm, setSubmittingForm] = useState(false);
  const [Name, setName] = useState<string>(EditSceneFormData?.name || "");
  const [Desc, setDesc] = useState<string>(EditSceneFormData?.desc || "");
  const [message, setMessage] = useState<string[]>([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    pauseUnity();
  }, []);

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setName(e.target.value);
  };

  const handleDescChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDesc(e.target.value);
  };

  // const CreateSceneHandler = async (e: React.FormEvent<HTMLFormElement>) => {
  //   e.preventDefault();
  //   setSubmittingForm(true);
  //   setLoading(true);

  //   try {
  //     let resp: any = null;
  //     if (EditSceneFormData) {
  //       resp = await updateScene({
  //         id: EditSceneFormData.id,
  //         name: Name,
  //         desc: Desc,
  //         unityJson: EditSceneFormData.unityJson,
  //       });
  //     } else {
  //       resp = await createScene({ name: Name, desc: Desc });
  //     }
  //     if (resp.status === 200) {
  //       GetAllScenes();
  //       setMessage([resp.statusText]);
  //       closeHandler();
  //     } else {
  //       setError(true);
  //       setMessage([resp.statusText]);
  //     }
  //   } catch (error) {
  //     setError(true);
  //     setMessage(["An error occurred"]);
  //   } finally {
  //     setLoading(false);
  //     setSubmittingForm(false);
  //   }
  // };

  return (
    <div className="fixed inset-0 z-[999999] w-full h-screen">
      <div
        className={classNames(
          "h-full",
          "absolute z-[99999] inset-0 w-full bg-black/70"
        )}
      />

      <div
        className={classNames(
          "top-1/2",
          "w-full h-auto opacity-95 rounded-md z-[9999999] absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[650px] max-h-[80vh]"
        )}
      >
        <div className="bg-dark p-3 w-full h-full rounded-md">
          {loading && (
            <div className="w-full h-[300px] grid place-content-center">
              <SmallLoader />
            </div>
          )}
          {!loading && (
            <>
              {/* name */}
              <div className="flex justify-between">
                <h1 className="text-2xl font-bold text-green">
                  {EditSceneFormData ? "Edit Project" : "New Project"}
                </h1>
                <div
                  className="bg-green p-1 rounded-md cursor-pointer grid place-content-center"
                  onClick={closeHandler}
                >
                  <FiX size={20} />
                </div>
              </div>
              {/* Content */}
              <div className="mt-5">
                {message.map((eachMessage, index) => (
                  <div className="pt-4" key={`ticket_${index}`}>
                    <p className={error ? "text-danger" : "text-success"}>
                      {eachMessage}
                    </p>
                  </div>
                ))}
                {/* <form className="mt-3" onSubmit={CreateSceneHandler}> */}
                <form className="mt-3" onSubmit={() => null}>
                  {/* <h1 className="text-lg text-center font-semibold">
                    {EditSceneFormData?EditSceneFormData.name:"New Scene"}
                  </h1> */}
                  <div className="mt-3">
                    <label htmlFor="name">Project Name</label>
                    <textarea
                      key="name"
                      id="name"
                      name="name"
                      placeholder="Scene Name"
                      rows={1}
                      required={true}
                      className="input mt-1 resize-none"
                      value={Name}
                      onChange={handleNameChange}
                    />
                  </div>
                  <div className="mt-3">
                    <label htmlFor="desc">Description</label>
                    <textarea
                      rows={5}
                      key="desc"
                      id="desc"
                      name="desc"
                      placeholder="Description"
                      className="input mt-1 max-h-[28vh] overflow-auto"
                      value={Desc}
                      onChange={handleDescChange}
                    />
                  </div>
                  <div className="flex flex-row-reverse mt-5 gap-5">
                    <input
                      name="create"
                      id="create"
                      key="create"
                      value={EditSceneFormData ? "Update" : "Create"}
                      type="submit"
                      className="btn btn-fill btn-primary"
                      disabled={submittingForm}
                    />
                    {submittingForm && <SmallLoader />}
                  </div>
                </form>
              </div>
            </>
          )}
          {/* Content end */}
        </div>
      </div>
    </div>
  );
};
export default AddSceneForm;
