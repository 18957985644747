import { Layout } from "processhub-components-lib";
import { Suspense, useState } from "react";
import Header from "components/layout/Header";
import DashboardConfiguration from "components/shared/DashboardConfiguration/DashboardConfiguration";
import Alarms from "pages/alarms/Alarms";
import AllAssets from "pages/all-assets/AllAssets";
import ExpressionContainer from "pages/expressions/ExpressionContainer";
import TagContainer from "pages/tags/TagContainer";
import TicketsContainer from "pages/tickets/TicketsContainer";
import UnityContainer from "pages/unity";
import { Navigate, Route, Routes } from "react-router-dom";
import { AuthGuard } from "utilities/AuthGuard";
import "./App.css";
import UsersContainer from "pages/users/UsersContainer";
import UnityLayout from "components/layout/UnityLayout";
import NewDashboard from "pages/NewDashboard";
import HealthContainer from "pages/HealthCheck";
import Logs from "pages/Logs";
import Documents from "pages/documents/Documents";
import { Watermark } from "processhub-components-lib";
import watermarkBg from "assets/watermarkBg.png";
import DataSources from "pages/dataSources/DataSources";
import ProjectsModal from "components/ProjectsModal/ProjectsModal";

function App() {

  const [showProjects, setShowProjects] = useState(false);

  return (
    <>
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route element={<AuthGuard />}>
            <Route element={<Layout><Header setShowProjects={setShowProjects} /></Layout>}>
              <Route path="/new-dashboard" element={<NewDashboard />} />
              <Route path="/" element={<Navigate to={`/dashboard`} />} />
              <Route path="/dashboard" element={<NewDashboard />} />
              <Route path="/dashboard-configuration">
                <Route element={<DashboardConfiguration />}>
                  <Route index element={<Navigate to="/dashboard-configuration/tags" />} />
                  <Route path="tags" element={<TagContainer />} />
                  <Route path="expressions" element={<ExpressionContainer />} />
                  <Route path="documents" element={<Documents />} />
                  <Route path="all-assets" element={<AllAssets />} />
                </Route>
              </Route>
              <Route path="/alarms" element={<Alarms />} />
              <Route path="/tickets" element={<TicketsContainer />} />
              <Route path="/users" element={<UsersContainer />} />
              <Route path="/logs" element={<Logs />} />
              <Route path="/data-sources" element={<DataSources />} />
              <Route path="/health" element={<HealthContainer />} />
            </Route>
            <Route element={<UnityLayout setShowProjects={setShowProjects} />}>
              <Route path="/3d-dashboard" element={<UnityContainer />} />
            </Route>
          </Route>
          <Route path="*" element={<p>Not Found</p>} />
        </Routes>
      </Suspense>
      <Watermark watermarkImage={watermarkBg} />
      {showProjects && <ProjectsModal setShowProjects={setShowProjects} />}
    </>
  );
}

export default App;
