import { Outlet } from "react-router-dom";
import logo from "assets/logo.svg";
import { useState, useEffect } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { SideMenu, Info, DateTime, UserMenu } from "processhub-components-lib";
import classNames from "utilities/ClassNames";
import allHooks from "hooks";
import { profileConfig } from "./config/userMenuConfig";
import { navLinks } from "./config/navLinksConfig";
import { useAuth } from "react-oidc-context";
import { ReactComponent as HealthsensorIcon } from "assets/healthsensorIcon.svg";
import { useNavigate } from "react-router-dom";
import { useUnityContext } from "react-unity-webgl";
import handleCacheControl from "pages/unity/config/handleCacheControl";
import { slideProjectName } from "./config/animations";
import { motion } from "framer-motion";
import extractUUIDs from "./utils/extractUUIDs";
import { getExtractedPath } from "utilities/extractedPath";
import { getCurrentSolution } from "api/solutions";

export default function UnityLayout({ setShowProjects }: any) {

  const auth = useAuth();
  const navigate = useNavigate();
  const logout = async () => auth.signoutRedirect();
  const [projectName, setProjectName] = useState('');
  const [showSideMenu, setShowMenu] = useState(false);
  const {
    sendMessage,
  } = useUnityContext({
    loaderUrl: "/Build/Unity/WebGL.loader.js",
    dataUrl: "/Build/Unity/WebGL.data",
    frameworkUrl: "/Build/Unity/WebGL.framework.js",
    codeUrl: "/Build/Unity/WebGL.wasm",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: "?Build/Unity/StreamingAssets",
  });
  const baseUrl = getExtractedPath();
  const { currOrg, curSol } = extractUUIDs(baseUrl);

  const getProjectInfo = async () => {
    const response = await getCurrentSolution(currOrg, curSol);
    if (response.status === 200) {
      return response.data.data.name;
    }
    return '';
  };

  useEffect(() => {
    const fetchProjectName = async () => {
      const userProjectName = await getProjectInfo();
      setProjectName(userProjectName);
    };

    fetchProjectName();
  }, []);

  useEffect(() => {
    if (showSideMenu) {
      sendMessage("__ReactInputBlocker", "SetBlockState", 1);
    }
    if (!showSideMenu) {
      sendMessage("__ReactInputBlocker", "SetBlockState", 0);
    }
  }, [showSideMenu]);

  return (
    <div className="h-full flex flex-col">
      <div id="navWrp" className="w-full flex-none bg-dark relative z-40">
        <div className={classNames("lg:h-12 xl-2xl:h-14 2xl:h-16",
          "mx-auto flex items-center px-3"
        )}>
          <div className="relative z-50 flex items-center gap-5">
            <div
              onClick={() => setShowMenu(!showSideMenu)}
              className="cursor-pointer duration-300"
            >
              {!showSideMenu && <FiMenu size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
              {showSideMenu && <FiArrowLeft size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
            </div>

            <Link to="/">
              <img
                src={logo}
                className={classNames("lg:h-6 xl-2xl:h-7 2xl:h-8")}
                alt="logo"
              />
            </Link>
            <motion.div
              initial="hidden"
              animate={showSideMenu ? "visible" : "hidden"}
              variants={slideProjectName}
              className="mt-1"
            >
              {projectName && (
                <span className="text-xl font-bold">
                  {projectName}
                </span>
              )}
            </motion.div>
          </div>
          <div className="flex-1" />
          <button onClick={() => setShowProjects(true)}>
            <div className={classNames("lg:h-[2.15rem] 2xl:h-[2.65rem] w-full", "rounded-lg bg-gradient-to-r from-green to-blue items-center justify-center")}>
              <button className={classNames("h-[2.40rem] w-40 text-xl lg:h-[1.95rem] 2xl:h-[2.40rem] lg:w-32 2xl:w-40 lg:text-sm 2xl:text-lg", "text-center bg-gradient-to-r from-gray to-black rounded-lg hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5")}>
                Projects List
              </button>
            </div>
          </button>
          <DateTime hooks={allHooks} />
          <div className="hover:text-green cursor-pointer w-max mr-6" onClick={() => navigate('/health')}>
            <HealthsensorIcon className={classNames("h-[23px] w-[23px] border-white rounded-full hover:text-green")} />
          </div>
          <Info hooks={allHooks} />
          <UserMenu profileConfig={profileConfig} hooks={allHooks} logout={logout} />
          <SideMenu
            closeMenu={() => setShowMenu(false)}
            showSideMenu={showSideMenu}
            navLinks={navLinks}
            hooks={allHooks}
          />
        </div>
      </div>
      <div className="relative overflow-hidden w-full h-full grow mx-auto">

        <Outlet />
      </div>
    </div>
  );
}
