/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getAllUnits = () => {
  return energyApi.get(`/TagMeta/GetAllUnits${extractedPath}`);
};

export const getUnitsByTrainId = (trainId = 0) => {
  return energyApi.get(`/TagMeta/GetUnitsByTrainId${extractedPath}?id=${trainId}`);
};

export const createUnit = (unit: any) => {
  return energyApi.post(`/TagMeta/CreateUnit${extractedPath}`, unit);
};

export const updateUnit = (unit: any) => {
  return energyApi.post(`/TagMeta/UpdateUnit${extractedPath}`, unit);
};

export const deleteUnit = (id: any) => {
  return energyApi.get(`/TagMeta/DeleteUnit${extractedPath}?id=${id}`);
};
