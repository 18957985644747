/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getAllEquipments = () => {
  return energyApi.get(`/TagMeta/GetAllEquipments${extractedPath}`);
};

export const getEquipmentsByUnitId = (unitId = 0) => {
  return energyApi.get(`/TagMeta/GetEquipmentsByUnitId${extractedPath}?id=${unitId}`);
};

export const createEquipment = (equipment: any) => {
  return energyApi.post(`/TagMeta/CreateEquipment${extractedPath}`, equipment);
};

export const updateEquipment = (equipment: any) => {
  return energyApi.post(`/TagMeta/UpdateEquipment${extractedPath}`, equipment);
};

export const deleteEquipment = (id: any) => {
  return energyApi.get(`/TagMeta/DeleteEquipment${extractedPath}?id=${id}`);
};
