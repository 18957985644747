import { updateAlarm } from "api/alarm";
import {
  disableTag,
  getAllTagsWithParents,
  getTagData
} from "api/tags";
import { Loader, BorderGradient, DataTable, TableManager, PopoverContainer, Modal } from "processhub-components-lib";
import { useEffect, useMemo, useState } from "react";
import Debounce from "utilities/Debounce";
import { filterArray } from "utilities/FilterArray";
import { parseFilters } from "utilities/ParseFilters";
import { usePins } from "hooks/usePins";
import classNames from "utilities/ClassNames";
import useApp from "hooks/useApp";
import { Item } from "types/common";
import modalConfig from "config/modal/modalConfig";
import allHooks from "hooks";
import { ActionName } from "types/common";
import { getTagsHealth } from "api/dashboard";
import downloadCSV from "utilities/handleCSV/downloadCSV";
import arrayToCSV from "utilities/handleCSV/arrayToCSV";

export default function TagContainer() {
  const { isScaled, userRole } = useApp();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [data, setData] = useState<any>(null);
  const [sortedData, setSortedData] = useState<any>(null);
  const [rawData, setRawData] = useState<any>(null);
  const [nullTagIds, setNullTagIds] = useState<any>(null);
  const [isDataSorted, setIsDataSorted] = useState(false);
  const { pinTag, unPin } = usePins();

  const [equipmentsFilters, setEquipmentsFilters] = useState<any>([]);
  const [equipmentsOptions, setEquipmentsOptions] = useState<any>();
  const [unitsFilters, setUnitsFilters] = useState<any>([]);
  const [unitsOptions, setUnitsOptions] = useState<any>();
  const [tag, setTag] = useState<any>({
    action: "",
    value: null,
  });

  const { dashboardPins } = usePins();

  // table
  const ITEMS_PER_PAGE = 300;
  const [currentPage, setCurrentPage] = useState(1);

  const getData = async () => {
    setLoading(true);
    const response: any = await getAllTagsWithParents();
    if (response.status === 200) {
      setRawData(response.data);
      //equipments
      const equipments = response.data.map((item: any) => item.equipmentName);
      setEquipmentsOptions(parseFilters(equipments));
      //equipments
      const units = response.data.map((item: any) => item.unitName);
      setUnitsOptions(parseFilters(units));
    } else {
      setError(true);
    }
    setLoading(false);
  };

  const refreshData = async () => {
    const response: any = await getAllTagsWithParents();
    if (response.status === 200) {
      setRawData(response.data);
    }
  };

  useEffect(() => {
    refreshData();
  }, [dashboardPins]);

  const alarm = async (id: any, isAlertTag: any) => {
    const response: any = await updateAlarm(id, isAlertTag ? 2 : 1);
    if (response.status === 200) {
      refreshData();
    }
  };

  const disable = async (id: any, isDisabled: any) => {
    const response: any = await disableTag(id, isDisabled ? false : true);
    if (response.status === 200) {
      refreshData();
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (rawData && rawData.length) {
      if (unitsFilters && unitsFilters?.length) {
        const result = filterArray(unitsFilters, rawData, "unitName");
        if (result.length > 0) setData(result);
      } else if (equipmentsFilters && equipmentsFilters?.length) {
        const result = filterArray(equipmentsFilters, rawData, "equipmentName");
        if (result.length > 0) setData(result);
      } else {
        setData(rawData);
      }
    }
  }, [rawData]);

  useEffect(() => {
    if (unitsFilters && unitsFilters?.length) {
      const result = filterArray(unitsFilters, rawData, "unitName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [unitsFilters]);

  useEffect(() => {
    if (equipmentsFilters && equipmentsFilters?.length) {
      const result = filterArray(equipmentsFilters, rawData, "equipmentName");
      if (result.length > 0) setData(result);
    } else {
      setData(rawData);
    }
  }, [equipmentsFilters]);

  useEffect(() => {
    const getHealthData = async () => {
      const response = await getTagsHealth();
      if (response.status === 200) {
        const unhealthyIds = new Set(response.data.map((obj: any) => obj.tagId));
        setNullTagIds(unhealthyIds);
      }
    };
    getHealthData();
  }, [rawData]);

  useEffect(() => {
    if (nullTagIds && data && data.length > 0) {
      if (!isDataSorted) {
        const sortedItems = data.map((item: any) => ({
          ...item,
          isNull: nullTagIds.has(item.id)
        })).sort((a: any, b: any) => {
          const aIsUnhealthy = nullTagIds.has(a.id);
          const bIsUnhealthy = nullTagIds.has(b.id);
          if (aIsUnhealthy === bIsUnhealthy) {
            return 0;
          }
          return aIsUnhealthy ? -1 : 1;
        });
        setSortedData(sortedItems);
      }
    }
  }, [nullTagIds, data, isDataSorted]);

  // Table
  const [keyword, setKeyword] = useState("");
  const [debouncedKeyword, setDebouncedKeyword] = useState("");
  const [filteredData, setfilteredData] = useState<any>(data);

  Debounce(() => setDebouncedKeyword(keyword), [keyword], 200);

  const rows = useMemo(() => {
    let items = sortedData;
    const keyword = debouncedKeyword.toString().toLowerCase();
    if (keyword !== "") {
      items = data?.filter(
        (item: any) =>
          item.tagNameIdentifier.toLowerCase().includes(keyword) ||
          item.tagNameIdentifier.toLowerCase() === keyword ||
          item.name.toLowerCase().includes(keyword) ||
          item.name.toLowerCase() === keyword
      );
    }

    if (items) {
      setfilteredData(items);
      return items?.slice(
        (currentPage - 1) * ITEMS_PER_PAGE,
        (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
      );
    }
  }, [sortedData, debouncedKeyword, currentPage]);

  const downloadFilterData = () => {
    if (filteredData && filteredData.length > 0) {
      const csvString = arrayToCSV(filteredData);
      downloadCSV(csvString, "filteredData.csv");
    }
  };

  const handleClick = (itemValue: Item, itemAction: ActionName) => {
    setTag({
      action: itemAction,
      value: itemValue
    });
  };

  const handleManagerBtnClick = () => {
    setTag({ action: "new", value: null });
  };

  const filterItems = [
    {
      title: 'Units',
      filters: unitsFilters,
      options: unitsOptions,
      setFilters: setUnitsFilters,
    },
    {
      title: 'Equipments',
      filters: equipmentsFilters,
      options: equipmentsOptions,
      setFilters: setEquipmentsFilters,
    }
  ];

  const downloadUnhealthyTags = async () => {
    const tagIds = Array.from(nullTagIds);
    const response = await getTagData(tagIds);
    if (response.status === 200) {
      const readyTags = response.data.map((tag: Item) => {
        return {
          tagNameIdentifier: tag.tagNameIdentifier,
          name: tag.name,
          desc: tag.desc,
          plantId: tag.plantId,
          plantName: tag.plantName,
          trainId: tag.trainId,
          trainName: tag.trainName,
          unitId: tag.unitId,
          unitName: tag.unitName,
          equipmentId: tag.equipmentId,
          equipmentName: tag.equipmentName,
          equipmentBubbleId: tag.equipmentBubbleId,
          errorMessage: tag.errorMessage,
          uom: tag.uom,
          minRange: tag.minRange,
          maxRange: tag.maxRange,
          minValue: tag.minValue,
          maxValue: tag.maxValue
        };
      });
      const csvString = arrayToCSV(readyTags);
      downloadCSV(csvString);
    }
  };

  return (
    <>
      {loading && (
        <div className="w-full h-[80vh] grid place-content-center">
          <Loader />
        </div>
      )}
      {error && (
        <div className="w-full h-[80vh] grid place-content-center">
          <p>Error retrieving Tags, try refreshing the page</p>
        </div>
      )}
      {!loading && !error && (
        <div
          className={classNames(
            isScaled ? "text-2xl" : "text-base",
            "pl-3 pt-3 pb-3 overflow-hidden overflow-y-scroll"
          )}
        >
          <TableManager userRole={userRole} type="tags" setKeyword={setKeyword} btnAction={handleManagerBtnClick} specialButtonText="Download Unhealthy Tags" downloadfiltereddata={downloadFilterData} specialButtonAction={downloadUnhealthyTags}>
            <PopoverContainer items={filterItems} />
          </TableManager>
          <div className="mt-3 h-[78vh]">
            <BorderGradient className=" overflow-hidden h-full">
              <DataTable userRole={userRole} type="tags" hasPins={true} hasActions={true} rows={rows} onClickHandler={handleClick} onActionClickHandler={handleClick} refresh={refreshData} pinTag={pinTag} unPin={unPin} />
            </BorderGradient>
          </div>
          <Modal page='tags' selectedTag={tag} setSelectedTag={setTag} isLoading={loading} refresh={refreshData} isAlarm={alarm} isDisabled={disable} hooks={allHooks} modalConfig={modalConfig} />
        </div>
      )}
    </>
  );
}
