import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getMappedAssetDocs = ({ trainId, unitId, equipmentId }: { trainId?: number, unitId?: number, equipmentId?: number }) => {
  let url = `/TagMeta/GetMappedAssetDocs${extractedPath}?`;
  if (trainId) url += `trainId=${trainId}&`;
  if (unitId) url += `unitId=${unitId}&`;
  if (equipmentId) url += `equipmentId=${equipmentId}&`;
  url = url.slice(0, -1); // Remove the trailing '&'

  return energyApi.get(url);
};

export const mapAssetDocs = ({ trainId, unitId, equipmentId, fileId,isMap=true }: { trainId?: number, unitId?: number, equipmentId?: number, fileId: string,isMap:boolean }) => {
  const url = `/TagMeta/MapAssetDocs${extractedPath}`;
  const body = [
    {
      fileId: fileId,
      isMapped: isMap,
      ...(trainId && !unitId && !equipmentId && { trainId }),
      ...(unitId && !trainId && { unitId }),
      ...(equipmentId && { equipmentId })
    }
  ];

  return energyApi.post(url, body);
};

export const mapAssetsDocs = (data: any) => {
  const url = `/TagMeta/MapAssetDocs${extractedPath}`;
  const body = data;
  return energyApi.post(url, body);
};

export const getAssetDocs = () => {
  const url = `/TagMeta/GetAssetDocs${extractedPath}`;
  return energyApi.get(url);
};