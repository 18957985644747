import { getScene } from "api/unity";
import { createContext, useContext, useEffect, useState } from "react";

export const SceneContext = createContext<any | null>(null);

export const SceneProvider = ({ children }: { children: any }) => {
  const [AllScenesList, setAllScenesList] = useState<any>(null);
  const [CurrentSceneId, setCurrentSceneId] = useState<number | null>(null);
  const [Loadmessage, setLoadmessage] = useState<any>(null);
  const [CurrentSceneData, setCurrentSceneData] = useState<any>(null);
  const [EditSceneFormData, setEditSceneFormData] = useState<any>(null);

  const LoadScene = async () => {
    const response = await getScene();
    if (response.status === 200) {
      setCurrentSceneData(response.data);
      console.log('this is data', response.data);
      setLoadmessage(`Project ${response.data.name} Loaded Successfully`);
    }
    else {
      setLoadmessage(`Error Loading the Selected Scene`);
    }
  };

  // const GetAllScenes: any = async () => {
  //   const response = await getSceneList();
  //   if (response.status === 200) {
  //     if (response.data && response.data.length > 0) {
  //       setAllScenesList(response.data);
  //       if (CurrentSceneId === null) {
  //         setCurrentSceneId(response.data[0].id);
  //         const response2 = await getScene(response.data[0].id);
  //         if (response2.status === 200) {
  //           setCurrentSceneData(response2.data);
  //         }
  //       }
  //     }
  //     else {
  //       setAllScenesList([]);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   GetAllScenes();
  // }, []);

  return (
    <SceneContext.Provider
      value={{
        AllScenesList,
        setAllScenesList,
        setCurrentSceneId,
        Loadmessage,
        setLoadmessage,
        EditSceneFormData,
        setEditSceneFormData,
        CurrentSceneData,
        setCurrentSceneData,
        LoadScene
      }}
    >
      {children}
    </SceneContext.Provider>
  );
};

export default function useScene() {
  return useContext(SceneContext);
}
