/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getBubblesMeta = () => {
  return energyApi.get(`/UnityData/GetUnityBubbleMeta${extractedPath}`);
};

export const getBubblesData = () => {
  return energyApi.get(`/UnityData/GetUnityBubbleData${extractedPath}`);
  // return api.get("/Unity/GetUnityBubbleData?dateTime=2023-10-02T22%3A46%3A00.000");
};

export const updateBubblePosition = (data: any) => {
  return energyApi.post(`/Unity/UpdateBubbleCordinates${extractedPath}`, data);
};

export const getNotes = () => {
  return energyApi.get(`/UnityData/GetUnityNoteMeta${extractedPath}`);
};

export const getEquipment = (id: any) => {
  return energyApi.get(`/UnityData/GetEquipment${extractedPath}?id=${id}`);
};

export const getUnit = (id: any) => {
  return energyApi.get(`/UnityData/GetUnit${extractedPath}?id=${id}`);
};

export const getWidget = (id: number) => {
  return energyApi.get(`/Unity/GetUnityWidget${extractedPath}` + `?id=${id}`);
};

export const createWidget = (data: any) => {
  return energyApi.post(`/Unity/CreateUnityWidget${extractedPath}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteWidget = (id: number) => {
  return energyApi.post(`/Unity/DeleteUnityWidget${extractedPath}` + `?id=${id}`);
};

export const updateWidget = (data: any) => {
  return energyApi.post(`/Unity/UpdateUnityWidget${extractedPath}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateWidgetPosition = (data: any) => {
  return energyApi.post(`/Unity/UpdateUnityWidgetCordinates${extractedPath}`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getAllWidgets = () => {
  return energyApi.get(`/Unity/GetUnityWidgetList${extractedPath}`);
};

export const getWidgetsMeta = () => {
  return energyApi.get(`/UnityData/GetUnityWidgetMeta${extractedPath}`);
};

export const getWidgetsData = () => {
  return energyApi.get(`/UnityData/GetUnityWidgetData${extractedPath}`);
};


export const getNote = (id: any) => {
  return energyApi.get(`Unity/GetNote${extractedPath}?id=${id}`);
};

export const getBubble = (id: any) => {
  return energyApi.get(`Unity/GetBubble${extractedPath}?id=${id}`);
};

export const downloadFile = (id: any) => {
  return energyApi.get(`/Unity/getFile${extractedPath}` + `?id=${id}`);
};

// Notes

export const createNote = (data: any) => {
  return energyApi.post(`/Unity/CreateNote${extractedPath}`, data);
};

export const updateNote = (data: any) => {
  return energyApi.post(`/Unity/UpdateNote${extractedPath}`, data);
};

export const updateNotesCoordinates = (data: any) => {
  return energyApi.post(`/Unity/UpdateNoteCordinates${extractedPath}`, data);
};

export const deleteNote = (id: any) => {
  return energyApi.get(`/Unity/DeleteNote${extractedPath}` + `?id=${id}`);
};

// Bubbles

export const createBubble = (data: any) => {
  return energyApi.post(`/Unity/CreateBubble${extractedPath}`, data);
};

export const updateBubble = (data: any) => {
  return energyApi.post(`/Unity/UpdateBubble${extractedPath}`, data);
};

export const deleteBubble = (id: any) => {
  return energyApi.get(`/Unity/DeleteBubble${extractedPath}` + `?id=${id}`);
};


export const getLabelsMeta = () => {
  return energyApi.get(`/Unity/GetUnityLabelMeta${extractedPath}`);
};

export const getLabel = (id: any) => {
  return energyApi.get(`Unity/GetLabel${extractedPath}?id=${id}`);
};

export const createLabel = (data: any) => {
  return energyApi.post(`/Unity/CreateLabel${extractedPath}`, data);
};

export const updateLabel = (data: any) => {
  return energyApi.post(`/Unity/UpdateLabel${extractedPath}`, data);
};

export const deleteLabel = (id: any) => {
  return energyApi.get(`/Unity/DeleteLabel${extractedPath}` + `?id=${id}`);
};

export const updateLabelPos = (data: any) => {
  return energyApi.post(`/Unity/UpdateLabelCordinates${extractedPath}`, data);
};

export const updateScene = (data: any) => {
  return energyApi.post(`/Unity/UpdateScene${extractedPath}`, data);
};

export const getScene = () => {
  return energyApi.get(`/Unity/GetScene${extractedPath}`);
};


