import { getAllTags as apiGetAllTags } from "api/tags";
import { getAllExpressions as apiGetAllExpressions } from "api/expression";
import { Loader } from "processhub-components-lib";
import {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import useApp from "./useApp";
import { getTrains } from "api/trains";
import { getAllUnits } from "api/units";
import { getAllEquipments } from "api/equipments";
import { getExtractedPath } from "utilities/extractedPath";
import EmptyPage from "pages/emptyPage/EmptyPage";

export const DataContext = createContext<any | null>(null);

const baseUrl = getExtractedPath();

export const DataProvider = ({ children }: { children: any }) => {
  const { isLoggedIn } = useApp();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const [plants, setPlants] = useState<any[]>([]);
  const [trains, setTrains] = useState<any[]>([]);
  const [units, setUnits] = useState<any[]>([]);
  const [equipments, setEquipments] = useState<any[]>([]);
  const [tags, setTags] = useState<any[]>([]);
  const [expressions, setExpressions] = useState<any[]>([]);
  const [daySky, setDaySky] = useState<number>(1);
  const [isPastData, setPastData] = useState<boolean>(false);
  const [pastDate, setPastDate] = useState<string | null>(null);

  const getData = async () => {
    setLoading(true);
    const tags: any = await apiGetAllTags();
    if (tags.status === 200) {
      setTags(tags.data);
    }

    const expressions: any = await apiGetAllExpressions();
    if (expressions.status === 200) {
      setExpressions(expressions.data);
    }

    const trains: any = await getTrains();
    if (trains.status === 200) {
      setTrains(trains.data);
    }

    const units: any = await getAllUnits();
    if (units.status === 200) {
      setUnits(units.data);
    }

    const equipments: any = await getAllEquipments();
    if (equipments.status === 200) {
      setEquipments(equipments.data);
    }

    setLoading(false);
  };

  useEffect(() => {
    if (isLoggedIn === "loggedIn") {
      getData();
    } else {
      setLoading(false);
    }
  }, [isLoggedIn]);

  const getTagInfo = (tagId: number) => {
    const tagInfo = tags.filter((tag) => tag.id === tagId);
    if (tagInfo.length) {
      return tagInfo[0];
    }
    return null;
  };

  const getExpressionInfo = (expressionId: number) => {
    const expressionInfo = expressions.filter(
      (expression) => expression.id === expressionId
    );
    if (expressionInfo.length) {
      return expressionInfo[0];
    }
    return null;
  };

  const trainsNames: any = trains.map((train: any) => ({
    label: train.name,
    value: train.id,
  }));
  const unitsNames = units.map((unit: any) => ({
    label: unit.name,
    value: unit.id,
  }));
  const equipmentsNames = equipments.map((equipment: any) => ({
    label: equipment.name,
    value: equipment.id,
  }));

  const trainsSet = [
    ...new Map(trainsNames.map((item: any) => [item.value, item])).values(),
  ];

  const unitsSet = [
    ...new Map(unitsNames.map((item: any) => [item.value, item])).values(),
  ];

  const equipmentsSet = [
    ...new Map(equipmentsNames.map((item: any) => [item.value, item])).values(),
  ];

  const setDayUnity = useCallback((day: number) => {
    setDaySky(day);
  }, []);

  const pastDateHandler = useCallback(
    (isPast: boolean, dateTime: string | null) => {
      setPastData(isPast);
      setPastDate(dateTime);
    },
    []
  );

  if (loading)
    return (
      <div className="bg-dark fixed inset-0 grid place-content-center">
        {baseUrl ?
          <Loader /> :
          <EmptyPage />
        }
      </div>
    );

  return (
    <DataContext.Provider
      value={{
        tags,
        expressions,
        loading,
        getTagInfo,
        getExpressionInfo,
        trains,
        trainsSet,
        unitsSet,
        equipmentsSet,
        setDayUnity,
        pastDateHandler,
        daySky,
        isPastData,
        pastDate,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

export default function useData() {
  return useContext(DataContext);
}
