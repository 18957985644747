/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getTrains = () => {
  return energyApi.get(`/TagMeta/GetTrainsByPlantId${extractedPath}?id=${1}`);
};

export const getAllTrains = () => {
  return energyApi.get(`/TagMeta/GetAllTrains${extractedPath}`);
};

export const createTrain = (train: any) => {
  return energyApi.post(`/TagMeta/CreateTrain${extractedPath}`, train);
};

export const updateTrain = (train: any) => {
  return energyApi.post(`/TagMeta/UpdateTrain${extractedPath}`, train);
};

export const deleteTrain = (id: number) => {
  return energyApi.get(`/TagMeta/DeleteTrain${extractedPath}?id=${id}`);
};
