/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { DynamicDashboardAPI, ExpPastDataAvgAPI, PinWidgetAPI, PlantTrainUnitEqAPI, TagPastDataAvgAPI, logsAPI } from "./types/apiTypes";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getWidgets = async (): Promise<DynamicDashboardAPI> => {
  return energyApi.get(`/Dashboard/GetDashboardWidget${extractedPath}`);
};

export const updateWidgets = async (data: any) => {
  return energyApi.post(`/Dashboard/UpdateDashboardWidget${extractedPath}`, data);
};

export const pinWidget = async (data: any) => {
  return energyApi.post(`/Dashboard/PinWidget${extractedPath}`, data);
};

export const getPinnedWidgets = async (): Promise<PinWidgetAPI> => {
  return energyApi.get(`/Dashboard/getPinnedWidgets${extractedPath}`);
};

export const getAllData = async (plantId = 1): Promise<PlantTrainUnitEqAPI> => {
  return energyApi.get(
    `/Dashboard/GetPlantTrainUnitsEquipmentsTagsByPlant${extractedPath}?plantId=${plantId}`
  );
};

export const getTagsHealth = async () => {
  return energyApi.get(`/Dashboard/GetTagsHealth${extractedPath}`);
};

export const getExpressionFutureData = async (
  id: string,
  avgType: string
) => {
  let url = `/Dashboard/GetPastExpressionDataWithAvgFuture${extractedPath}?expressionId=${id}`;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return energyApi.get(url);
};

export const getTagsPastData = async (
  id: string,
  days: number,
  avgType: string
): Promise<TagPastDataAvgAPI> => {
  let url = `/Dashboard/GetTagPastDataWithAvg${extractedPath}?tagId=${id}`;
  days ? (url += `&pastNumberOfDays=${days}`) : null;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return energyApi.get(url);
};

export const getExpressionPastData = async (
  id: string,
  days: number,
  avgType: string
): Promise<ExpPastDataAvgAPI> => {
  let url = `/Dashboard/GetPastExpressionDataWithAvg${extractedPath}?expressionId=${id}`;
  days ? (url += `&pastNumberOfDays=${days}`) : null;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return energyApi.get(url);
};

export const getSystemLogs = ({ durType = 3, count = 10, action = null, module = null }): Promise<logsAPI> => {
  let url: any = `/SystemLog/GetSystemLogsByDateTime${extractedPath}` + `?durType=${durType}&count=${count}`;
  if (module != null) {
    url += `&module=${module}`;
  }
  if (action != null) {
    url += `&action=${action}`;
  }
  return energyApi.get(url);
};

export const getTagFutureData = async (
  id: string,
  avgType: string
) => {
  let url = `/Dashboard/GetTagPastDataWithAvgFuture${extractedPath}?tagId=${id}`;
  avgType ? (url += `&avgType=${avgType}`) : null;
  return energyApi.get(url);
};