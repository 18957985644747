import { useState, useEffect } from "react";
import ProjectContainer from "./ProjectContainer";

const operationalExcelenceData = [
  {
    title: "EnergyHub",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://energyhub.processhub.ai/",
    isEditable: false
  },
  {
    title: "Leap24",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://leap24.processhub.ai/",
    isEditable: false
  },
  {
    title: "Data Center",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://datacenter.processhub.ai/",
    isEditable: false
  },
  {
    title: "Water Tower",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://culturalhub.processhub.ai/",
    isEditable: false
  },
  {
    title: "Car Simulator",
    type: "FREE TRIAL",
    bgColor: "bg-[#2b5f8a]",
    icon: "/images/cpu.svg",
    status: "Public Project",
    link: "https://car.processhub.ai/",
    isEditable: false
  },
];

const ProjectHome: React.FC<any> = ({ userProjects }) => {

  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [allProjects, setAllProjects] = useState<any>([]);

  useEffect(() => {
    if (userProjects) {
      setIsLoading(true);
      setAllProjects([...operationalExcelenceData, ...userProjects]);
      setIsLoading(false);
    } else {
      setAllProjects([operationalExcelenceData]);
    }
  }, [userProjects]);

  return (
    <section
      style={{
        backgroundImage: `url(/images/about.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "bottom",
        filter: "opacity(100%)",
      }}
      className=" top-0 left-0 w-full h-max"
    >
      <div className="absolute inset-0 bg-gray opacity-40 z-0"></div>
      <div className="relative z-10">
        <ProjectContainer title='OPERATIONAL EXCELENCE' data={allProjects} isLoading={false} />
      </div>
    </section>
  );
};

export default ProjectHome;