let extractedPath: string = "";

// Utility function to set the extracted path
export const setExtractedPath = () => {
  const match = window.location.pathname.match(/\/o\/[^/]+\/s\/[^/]+/);
  extractedPath = match ? match[0] : '';
};

// Utility function to get the extracted path
export const getExtractedPath = () => extractedPath;

// Call this function on initial load or when the URL changes
setExtractedPath();