import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import styles from "./googlemapstyles.json";
import { BorderGradient } from "processhub-components-lib";
import { useState, useEffect } from "react";
import ProjectHome from "./PorojectContainer/ProjectHome";
import { FiX } from "react-icons/fi";
import { getSolutions } from "api/solutions";
import { getExtractedPath } from "utilities/extractedPath";

const ProjectsModal = ({ setShowProjects }: any) => {

  const baseUrl = getExtractedPath();
  const match = baseUrl.match(/\/o\/([^/]+)/);
  const uid = match ? match[1] : '';
  const [userProjects, setUserProjects] = useState<any>([]);

  const getAllProjects = async (id: string) => {

    const response = await getSolutions(id);
    if (response.status === 200) {
      const newProjects = response.data.list.map((item: any) => {
        return {
          ...item,
          title: item.name,
          type: "PROJECT",
          bgColor: "bg-gradient-to-b from-green to-blue",
          icon: "/images/cpu.svg",
          status: "Private Project",
          link: item.url,
          isEditable: true
        };
      });
      setUserProjects([...newProjects]);
    }
  };

  useEffect(() => {
    getAllProjects(uid);
  }, []);

  const [menuShown, setMenuShown] = useState(false);
  const [loading, setLoading] = useState(true);
  const center = {
    lat: 24,
    lng: 42.388945,
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyCaAnlPllExl2Yuk5G6RENFb0zlBQUXFWY",
  });

  useEffect(() => {
    if (isLoaded) {
      setLoading(false);
    }
  }, [isLoaded]);

  const onLoad = () => {
    setMenuShown(true);
  };

  const containerStyle = {
    width: "100%",
    height: "44vh",
    padding: 0,
    margin: 0,
  };

  return !loading ? (
    <div className="fixed z-50 inset-0 flex justify-center items-center bg-black bg-opacity-60">
      <BorderGradient>
        <div className="relative bg-dark rounded-lg shadow-lg w-[52.3vw] overflow-auto">
          <span className="absolute right-3 top-3 z-50">
            <button
              className="cursor-pointer bg-green rounded-md p-1"
              onClick={() => setShowProjects(false)}
            >
              <FiX size={20} />
            </button>
          </span>
          {menuShown && <ProjectHome userProjects={userProjects} />}
          <GoogleMap
            mapContainerStyle={{ ...containerStyle, position: 'relative', zIndex: 1 }}
            center={center}
            zoom={5.5}
            onLoad={onLoad}
            options={{
              styles: styles,
              zoomControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
              minZoom: 3,
              maxZoom: 7,
            }}
          >
            <Marker
              label={{
                text: "Water Tower",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 26.4, lng: 50 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_CULTURAL_APP}`, "_self");
              }}
            />
            <Marker
              label={{
                text: "Energy",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 27.7, lng: 48.8 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_ENERGY_APP}`, "_self");
              }}
            />

            <Marker
              label={{
                text: "Leap24",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 25.2, lng: 46.4 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_LEAP24_APP}`, "_self");
              }}
            />

            <Marker
              label={{
                text: "Data Center",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 24.7, lng: 46.85 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_DATACENTER_APP}`, "_self");
              }}
            />

            <Marker
              label={{
                text: "Car Simulator",
                color: "#12E4DE",
                className: "watertowertext",
              }}
              position={{ lat: 21, lng: 39.3 }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_REALESTATE_APP}`, "_self");
              }}
            />
            {userProjects &&
              userProjects.map((project: any) => {
                const coords = JSON.parse(project.coordinates); // Parse coordinates
                if (coords) {
                  return (
                    <Marker
                      key={project.id} // Ensure unique key for each marker
                      label={{
                        text: project.name,
                        color: "#12E4DE",
                        className: "watertowertext",
                      }}
                      position={{ lat: coords[0], lng: coords[1] }}
                      onClick={() => {
                        window.open(project.url, "_blank");
                      }}
                    />
                  );
                }
                return null;
              })}
          </GoogleMap>
        </div>
      </BorderGradient>
    </div>
  ) : (
    <></>
  );
};

export default ProjectsModal;