/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

function subtractHours(date: any) {
  date.setHours(date.getHours() - 720);
  return date;
}

const dateNow = new Date();
const dateTime = subtractHours(dateNow);

export const getAllTags = () => {
  return energyApi.get(`/TagMeta/GetAllTags${extractedPath}`);
};

export const getTagData = (
  ids: any,
  dateTime?: string,
) => {
  let url = `Dashboard/GetTagData${extractedPath}`;
  dateTime ? (url += `?dateTime=${dateTime}`) : null;
  return energyApi.post(url, ids);
};

export const mapEquipmentTag = (tags: any) => {
  return energyApi.post(`/TagMeta/MapEquipmentTag${extractedPath}`, tags);
};

export const getAllTagsWithParents = () => {
  return energyApi.get(`/TagMeta/GetAllTagsWithParent${extractedPath}`);
};

export const createTag = (tag: any) => {
  return energyApi.post(`/TagMeta/CreateTag${extractedPath}`, tag);
};

export const updateTag = (tag: any) => {
  return energyApi.post(`/TagMeta/UpdateTag${extractedPath}`, tag);
};

export const resetTag = (id: any) => {
  return energyApi.get(`/TagMeta/ResetTagRange${extractedPath}?id=${id}`);
};

export const uploadTag = (data: any, signal: any) => {
  return energyApi.post(`TagData/Upload${extractedPath}`, data);
};

export const deleteTag = (id: any) => {
  return energyApi.get(`/TagMeta/DeleteTag${extractedPath}?id=${id}`);
};

export const getTrainsByPlantId = (id: any) => {
  return energyApi.get(`/TagMeta/GetTrainsByPlantId${extractedPath}?id=${id}`);
};

export const disableTag = (tagId: number, status: any) => {
  return energyApi.post(
    `/TagMeta/SetTagDisabled${extractedPath}?tagId=${tagId}&isDisabled=${status}`
  );
};

export const getTagsByEquipmentsId = (equipmentId: any) => {
  return energyApi.get(`/TagMeta/GetTagsByEquipmentsId${extractedPath}?id=${equipmentId}`);
};

export const downloadTag = (id: any) => {
  return energyApi.get(`/TagData/DownloadTagData${extractedPath}?id=${id}`);
};

export const clearTag = (id: any) => {
  return energyApi.get(`/TagData/ClearTagData${extractedPath}?id=${id}`);
};
