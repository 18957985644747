import useSignalR from "hooks/useSignalR";
import useScreen from "hooks/useScreen";
import WebGLContext, { IBubbleDetails } from "./WebGlContext";
import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import {
  getNotes,
  updateBubblePosition as apiUpdateBubblePosition,
  getLabelsMeta,
  getWidget,
  deleteWidget as deleteWidgetApi,
  deleteBubble as deleteBubbleApi,
  deleteLabel as deleteLabelApi,
  deleteNote as deleteNoteApi,
  getNote,
  updateWidgetPosition as updateWidgetPositionApi,
  updateLabelPos,
  getAllWidgets,
  getWidgetsData,
  getWidgetsMeta,
  updateScene,
  getScene,
  updateNotesCoordinates
} from "api/unity";
import unityWidgetConfig from "config/unity/unityWidgetConfig";
import { usePins } from "hooks/usePins";
import { Widget, Label, Note, Pin, SplashScreen, Bubble, ChartsContainer, PreviewFiles } from 'processhub-components-lib';
import { getTagData } from "api/tags";
import { getExpressionData } from "api/expression";
import { useSearchParams } from "react-router-dom";
import useData from "hooks/useData";
import { useAuth } from "react-oidc-context";
import useApp from "hooks/useApp";
import { getData } from "./domain/getBubbleNotesUnitsEquipments";
import handleCacheControl from "./config/handleCacheControl";
import { TagExpressionHandler } from "./domain/GetUnitEquipmentforTagsExpressions";
import { loadDataFromContext } from "./domain/loadDatafromContext";
import { Item } from "types/common";
import { bubbleProps, bubbledataProps, bubblenoteProps } from "types/unitytypes";
import { equipmentProps, unitsProps } from "types/tagexpressiontypes";
import SceneList from "./SceneList";
import AddSceneForm from "./AddScene";
import { FiCheckCircle } from "react-icons/fi";
import useScene from "hooks/useScene";
import { SmallLoader } from "processhub-components-lib";
import allHooks from "hooks";
import unityChartsContainerConfig from "config/unity/unityChartsContainerConfig'";
import noteFormConfig from "config/unity/noteFormConfig";
import labelConfig from "config/unity/LabelConfig";
import { getFileByUniqueId } from "api/fileManager";
import getFileExtension from "utilities/getFileExtension";

const WebGL: React.FC = (props) => {
  const { isScaled } = useScreen();
  const { unityPins, unpinAllWidgets } = usePins();
  const { setIsLoggedIn, user } = useApp();
  // const { isScaled } = useScreen();
  const [searchParams] = useSearchParams();
  const [unityLoaded, setUnityLoaded] = useState<boolean | null>(null);
  const [selectedWidget, setSelectedWidget] = useState<number | null>(null);
  const [widgetCoordinates, setWidgetCoordinates] = useState<number[]>([]);
  const [widgetModalMode, setWidgetModalMode] = useState<'none' | 'create' | 'modify' | 'delete'>('none');
  const [widgetPositionUpdate, setWidgetPositionUpdate] = useState<any>(null);
  const [itemToDelete, setItemToDelete] = useState<{ item: string, id: number | null }>({ item: '', id: null });
  const [widgetForBubbleId, setWidgetForBubbleId] = useState<number | null>(null);
  const [widgetData, setWidgetData] = useState({
    unitId: null,
    equipmentId: null,
    showEquipmentWidget: false,
    widgets: [],
    name: "",
    desc: "",
    x: 0,
    y: 0,
    z: 0
  });
  const [tagsIds, setTagsIds] = useState([]);
  const [expressionIds, setExpressionsIds] = useState([]);
  const [isMovementDisabled, setIsMovementDisabled] = useState(0);
  const [allWidgets, setAllWidgets] = useState<any>([]);
  const {
    unityProvider,
    sendMessage,
    addEventListener,
    removeEventListener,
    isLoaded,
  } = useUnityContext({
    loaderUrl: "/Build/Unity/WebGL.loader.js",
    dataUrl: "/Build/Unity/WebGL.data",
    frameworkUrl: "/Build/Unity/WebGL.framework.js",
    codeUrl: "/Build/Unity/WebGL.wasm",
    cacheControl: handleCacheControl,
    streamingAssetsUrl: "?Build/Unity/StreamingAssets",
  });

  const { unityDataHandler } = useSignalR();

  interface bubbleAllDetails {
    bubbletype: string;
    unitId: number | null;
    equipmentId: number | null;
  }
  const {
    getUnitInfo,
    getEquipmentInfo,
    getTagInfo,
    getTagsInfo,
    getEquipmentExpressionsInfo,
    getUnitExpressionsInfo,
    daySky,
    sceneId,
    pastDate
  } = useData();
  const auth = useAuth();
  const [rerenderBubble, setRenderBubble] = useState(false);
  const [rerenderBubbleData, setRenderBubbleData] = useState(false);
  const [chartData, setChartData] = useState<Item[] | null>(null);
  const [bubbleDetails, setBubbleDetails] = useState<IBubbleDetails | null>(
    null
  );
  const [bubbleAllDetails, setbubbleAllDetails] = useState<bubbleAllDetails | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoader, setModalLoader] = useState(true);
  const [playerState, setPlayerState] = useState(false);
  const [ticketTags, updateTicketTags] = useState<number[]>([]);
  const [showLogo, setShowLogo] = useState(false);
  const [chartType, setChartType] = useState<any>(null);
  const [selectedUnit, setSelectedUnit] = useState<number | null>(null);
  const [allBubbles, setAllBubbles] = useState<bubbleProps[] | null>(null);
  const [bubblesMeta, setBubblesMeta] = useState<bubbledataProps[] | null>(null);
  const [allNotes, setAllNotes] = useState<bubblenoteProps[] | null>(null);
  const [allLabels, setAllLabels] = useState<any>(null);
  const [equipments, setEquipments] = useState<equipmentProps[] | null>(null);
  const [units, setUnits] = useState<unitsProps[] | null>(null);
  const [bubblePopUp, setBubblePopUp] = useState<any>(null);
  const [bubbleModalOpen, setBubbleModalOpen] = useState(false);
  const [popupBubbleId, setBubbleId] = useState<number | null>(null);
  const [notePopup, setNotePopup] = useState<any>(null);
  const [popupNoteId, setNoteId] = useState<number | null>(null);
  const [noteModalOpen, setNoteModalOpen] = useState(false);
  const [LabelPopup, setLabelPopup] = useState<any>(null);
  const [popupLabelId, setLabelId] = useState<number | null>(null);
  const [LabelModalOpen, setLabelModalOpen] = useState(false);
  const [showPins, setShowPins] = useState(true);
  const [loadingStatus, setLoadingStatus] = useState(
    "Loading data from the network"
  );
  const [unityPrefabLoading, setUnityPrefabLoading] = useState(0);
  const [unityLoadingProgress, setUnityLoadingProgress] = useState(0);
  const [fakeLoading, setFakeLoading] = useState(false);
  const [unpinConfirmation, setUnpinConfirmation] = useState(false);
  const [isSceneModalOpen, setisSceneModalOpen] = useState(false);
  const [scenesData, setScenesData] = useState<any>(null);
  const [isLoadingProcess, setIsLoadingProcess] = useState(false);
  const [previewData, setPreviewData] = useState<any>({ uniqueId: '', fileName: '', data: '' });

  /* STATES RELATED TO ADD/EDIT/DELETE SCENES */
  const [AllScenesModalOpen, setAllScenesModalOpen] = useState(false);
  const [SceneHandlerModalOpen, setSceneHandlerModalOpen] = useState(false);
  const {
    AllScenesList,
    setAllScenesList,
    setCurrentSceneId,
    Loadmessage,
    setLoadmessage,
    EditSceneFormData,
    setEditSceneFormData,
    CurrentSceneData,
    setCurrentSceneData,
    GetAllScenes,
    LoadScene } = useScene();

  const baseUrl = localStorage.getItem("baseUrl");
  const webglUrl = localStorage.getItem("webglUrl");
  const webglPort = localStorage.getItem("webglPort");

  function unityLoadingInterval() {
    const intervalId = setInterval(() => {
      setUnityLoadingProgress((prevProgress) => {
        if (prevProgress !== 99) {
          return prevProgress + 1;
        } else {
          clearInterval(intervalId);
          return prevProgress;
        }
      });
    }, 3000);
  }
  useEffect(() => {
    if (!fakeLoading) {
      if (unityPrefabLoading < 90) {
        setUnityLoadingProgress(unityPrefabLoading);
      } else if (unityPrefabLoading === 90) {
        setUnityLoadingProgress(90);
        unityLoadingInterval();
        setFakeLoading(true);
      }
    }
  }, [unityPrefabLoading]);

  useEffect(() => {
    if (selectedWidget !== null) {
      const getWidgetData = async (id: number) => {
        const response = await getWidget(id);
        if (response.status === 200) {
          setWidgetData({ ...response.data, id });
          setWidgetModalMode('modify');
        }
        else {
          console.log('error fetching widget');
        }
      };
      getWidgetData(selectedWidget);
    }
  }, [selectedWidget]);

  useEffect(() => {
    if (widgetCoordinates.length > 0) {
      setWidgetData((prev: any) => ({
        ...prev,
        x: widgetCoordinates[0],
        y: widgetCoordinates[1],
        z: widgetCoordinates[2]
      }));
    }
  }, [widgetCoordinates]);

  useEffect(() => {
    (async () => {
      const data = await getData();
      setBubblesMeta(data.BubblesMeta);
      setAllBubbles(data.BubblesData);
      setAllNotes(data.Notes);
      setEquipments(data.Equipments);
      setUnits(data.Units);
      setAllLabels(data.LabelsMeta);

      sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
      sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
    })();
  }, []);

  const updateTagsData = async () => {
    const newData = await getData();
    setUnits(newData.Units);
    setEquipments(newData.Equipments);
  };

  const refreshUnityData = async () => {
    const data = await getData(true);
    setBubblesMeta(data.BubblesMeta);
    setAllBubbles(data.BubblesData);
    sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
  };

  useEffect(() => {
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
    sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
    sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
  }, [allBubbles, bubblesMeta, allNotes, allLabels]);

  const unityProgressBarHandler: any = useCallback((amount: number) => {
    const loadingAmount = Math.round(90 * amount);
    setUnityPrefabLoading(loadingAmount);
  }, []);

  const updateNotes = async () => {
    const notesResponse = await getNotes();
    if (notesResponse.status === 200) {
      setAllNotes(notesResponse.data);
    }
    sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
  };

  const getFileData = async (id: any) => {
    const response = await getFileByUniqueId(id);
    if (response.status === 200) {
      return response.data;
    }
  };
  const onFileClick = async (file: any) => {
    const { uniqueId, fileName } = file;
    const data = await getFileData(uniqueId);
    setPreviewData({
      uniqueId, fileName, data
    });
  };

  const updateLabels = async () => {
    const LabelsResponse = await getLabelsMeta();
    if (LabelsResponse.status === 200) {
      setAllLabels(LabelsResponse.data);
    }
    sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
  };

  useEffect(() => {
    if (isLoaded) {
      setLoadingStatus(
        "We are building your digital twin, it will take from 30 seconds till 4 min depends on graphic card"
      );
    }
  }, [isLoaded]);

  const handleModalClose = () => {
    setisSceneModalOpen(false);
    setScenesData({});
  };

  const handleScenesSubmit = async () => {
    setIsLoadingProcess(true);
    const response = await updateScene(scenesData);
    if (response.status === 200) {
      setLoadmessage(`Project ${name} Saved Successfully`);
    } else {
      setLoadmessage(`Error Saving the Selected Scene`);
    }
    setIsLoadingProcess(false);
    handleModalClose();
  };

  const unityReadyHandler = useCallback(() => {
    LoadScene();
    setUnityLoaded(true);
    setShowLogo(true);
    setTimeout(() => {
      setShowLogo(false);
    }, 5000);
    if (baseUrl) {
      sendMessage("Utilities", "SetBackendUrl", baseUrl);
    }
    if (webglUrl && webglPort) {
      const webglserverurl = { webglUrl: webglUrl, webglPort: webglPort };
      sendMessage(
        "Utilities",
        "SetWebGlServerUrl",
        JSON.stringify(webglserverurl)
      );
    }
  }, [sendMessage]);

  useEffect(() => {
    if (unityLoaded) {
      document.getElementById("navWrp")?.classList?.add("!static");
      setTimeout(() => {
        const bubbleId = searchParams.get("bubbleId");
        if (bubbleId) {
          sendMessage("Utilities", "MoveUserToBubble", parseInt(bubbleId));
        }
      }, 5000);
    } else {
      document.getElementById("navWrp")?.classList?.remove("!static");
    }
  }, [unityLoaded, sendMessage]);

  useEffect(() => {
    setRenderBubble(true);
  }, [allBubbles]);

  useEffect(() => {
    if (unityLoaded) {
      sendMessage("MainBubble", "SetBubbleList", JSON.stringify(bubblesMeta));
    }
  }, [unityLoaded]);

  const sendBubbleData = useCallback(
    (reload: boolean) => {
      if (unityLoaded) {
        const dataCollection: any = loadDataFromContext(
          allBubbles,
          getUnitInfo,
          getEquipmentInfo,
          getTagInfo
        );
        const bubbleAndData = {
          rerender: reload,
          bubbles: dataCollection,
        };
        sendMessage("MainBubble", "SetBubbleData", JSON.stringify(allBubbles));
      }
    },
    [unityLoaded, sendMessage, loadDataFromContext]
  );

  useEffect(() => {
    if (unityLoaded && rerenderBubble) {
      sendBubbleData(true);
      setRenderBubble(false);
    }
    // eslint-disable-next-line
  }, [rerenderBubble, sendBubbleData, unityLoaded]);

  useEffect(() => {
    setRenderBubbleData(true);
  }, [units]);

  useEffect(() => {
    if (unityLoaded && rerenderBubbleData) {
      sendBubbleData(false);
      setRenderBubbleData(false);
    }
    // eslint-disable-next-line
  }, [rerenderBubbleData, sendBubbleData, unityLoaded]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  useEffect(() => {
    if (unityLoaded) {
      if (allNotes) {
        const notesCollection = allNotes.map((eachNote: any) => {
          const { id, x, y, z, header, note } = eachNote;
          return { id, x, y, z, header, note };
        });
        sendMessage(
          "MainNote",
          "SetNotesList",
          JSON.stringify(notesCollection)
        );
      } else {
        sendMessage("MainNote", "SetNotesList", JSON.stringify(allNotes));
      }
    }
    // eslint-disable-next-line
  }, [allNotes, unityLoaded]);

  useEffect(() => {
    if (unityLoaded) {
      if (allLabels) {
        const LabelsCollection = allLabels.map((eachLabel: any) => {
          const { id, x, y, z, prefabId, prefabParentId, name } = eachLabel;
          return { id, x, y, z, prefabId, prefabParentId, name };
        });
        sendMessage(
          "MainLabel",
          "SetLabelsList",
          JSON.stringify(LabelsCollection)
        );
      } else {
        sendMessage("MainLabel", "SetLabelsList", JSON.stringify(allLabels));
      }
    }
    // eslint-disable-next-line
  }, [allLabels, unityLoaded]);

  const handleConfigClick = () => {
    setSelectedWidget(widgetForBubbleId);
  };

  const handleTogglePins = useCallback((status: any) => {
    if (status === 3) {
      setShowPins(false);
    }
    if (status !== 3) {
      setShowPins(true);
    }
  }, []);

  const createBubble: any = useCallback((x: number, y: number, z: number) => {
    const bubbleDetails = {
      x,
      y,
      z,
      unitId: null,
      equipmentId: null,
    };
    setBubblePopUp(bubbleDetails);
    setBubbleModalOpen(true);
    setBubbleId(null);
  }, []);

  const updateBubblePosition: any = useCallback(async (bubbles: any) => {
    if (bubbles) {
      const data = JSON.parse(bubbles);
      const response: any = await apiUpdateBubblePosition(data);
      if (response.status === 200) {
        refreshUnityData();
      }
    }
  }, []);

  const getNoteData = async (id: number) => {
    const response = await getNote(id);
    if (response.status === 200) {
      return response.data;
    }
  };

  const updateNotePosition: any = useCallback((noteInfo: any) => {
    if (noteInfo) {
      const noteUpdatedData = JSON.parse(noteInfo);

      (async () => {
        const response = await updateNotesCoordinates(noteUpdatedData);
        if (response.status === 200 || response.status === 204) {
          updateNotes();
        }
      })();
    }
  }, []);


  const configureBubbleHandler: any = useCallback((bubbleId: any) => {
    if (bubbleId) {
      setBubblePopUp(null);
      setBubbleModalOpen(true);
      setBubbleId(bubbleId);
    }
  }, []);

  const createNote: any = useCallback((x: number, y: number, z: number) => {
    const noteDetails = { x, y, z };
    setNotePopup(noteDetails);
    setNoteId(null);
    setNoteModalOpen(true);
  }, []);

  const openNote: any = useCallback((noteId: number) => {
    setNotePopup(null);
    setNoteId(noteId);
    setNoteModalOpen(true);
  }, []);


  const createLabel: any = useCallback((x: number, y: number, z: number) => {
    const LabelDetails = { x, y, z };
    setLabelPopup(LabelDetails);
    setLabelId(null);
    setLabelModalOpen(true);
  }, []);

  const openLabel: any = useCallback((LabelId: number) => {
    setLabelPopup(null);
    setLabelId(LabelId);
    setTimeout(() => {
      setLabelModalOpen(true);
    }, 500);
  }, []);


  const updateLabelPosition: any = useCallback(async (labels: any) => {
    if (labels) {
      const data = JSON.parse(labels);
      const response = await updateLabelPos(data);
      if (response.status === 200) {
        const labelsMetaResponse = await getLabelsMeta();
        if (labelsMetaResponse.status === 200) {
          setAllLabels(labelsMetaResponse.data);
        }
      }
    }
  }, []);

  const fetchLatestData = useCallback(() => {
    sendMessage("UpdateBubbles", "UpdateFromSignalR");
  }, [sendMessage]);

  useEffect(() => {
    if (unityLoaded) {
      sendMessage("Utilities", "updateSkyBox", daySky);
    }
  }, [unityLoaded, daySky, sendMessage]);

  const tagsAndBubbleConfigHandlerAction: any = useCallback(
    async (bubbleId: number, equipmentId: number, type: string) => {
      const bubbleTagDetails: any = await TagExpressionHandler(
        bubbleId,
        equipmentId,
        type,
        "tag"
      );
      if (type === "unconfigured") {
        setSelectedUnit(null);
        configureBubbleHandler(bubbleId);
      } else {
        setbubbleAllDetails({ bubbletype: type, unitId: bubbleTagDetails.unitId, equipmentId: type == "equipment" ? equipmentId : null });
        setSelectedUnit(bubbleTagDetails.unitId);
        setBubbleDetails(bubbleTagDetails.details);
        if (
          bubbleTagDetails.collections &&
          bubbleTagDetails.collections.length > 0
        ) {
          setChartData(bubbleTagDetails.collections);
          setModalOpen(true);
          setModalLoader(false);
          setBubbleId(bubbleId);
        } else {
          configureBubbleHandler(bubbleId);
        }
      }
    },
    [getTagsInfo, getEquipmentInfo, configureBubbleHandler, equipments]
  );

  const tagsAndBubbleConfigHandler: any = useCallback(
    async (id: any, AssertId: any, AssertType: any) => {
      setbubbleAllDetails({ bubbletype: AssertType, unitId: AssertId, equipmentId: AssertType == "equipment" ? id : null });
      setWidgetForBubbleId(id);
      setChartType(AssertType);
      tagsAndBubbleConfigHandlerAction(id, AssertId, AssertType);
    },
    []
  );

  const expressionsHandler: any = useCallback(
    async (bubbleId: number, assertId: number, type: string) => {
      const bubbleExpressionDetails: any = await TagExpressionHandler(
        bubbleId,
        assertId,
        type,
        "expression"
      );
      setSelectedUnit(bubbleExpressionDetails.unitId);
      setBubbleDetails(bubbleExpressionDetails.details);
      setbubbleAllDetails({ bubbletype: type, unitId: bubbleExpressionDetails.unitId, equipmentId: type == "equipment" ? assertId : null });
      if (
        bubbleExpressionDetails.collections &&
        bubbleExpressionDetails.collections.length > 0
      ) {
        setChartData(bubbleExpressionDetails.collections);
        setModalOpen(true);
        setModalLoader(false);
        setBubbleId(bubbleId);
      } else {
        configureBubbleHandler(bubbleId);
      }
    },
    [
      getEquipmentExpressionsInfo,
      getUnitExpressionsInfo,
      getUnitInfo,
      getEquipmentInfo,
      configureBubbleHandler,
      units,
      equipments,
    ]
  );

  const logOut = useCallback(() => {
    (async () => {
      auth.signoutRedirect();
      setIsLoggedIn("loggedOut");
    })();
  }, []);

  const handlePlayerState: any = useCallback((state: boolean) => {
    setPlayerState(state);
  }, []);

  /* When WebGL component unmounts (in cleanup function) - enable the browser keyboard inputs */
  useEffect(() => {
    return () => {
      if (unityLoaded) {
        pauseUnity();
      }
    };
  }, [unityLoaded]);

  useEffect(() => {
    switch (sceneId) {
      case 1:
        sendMessage("Scene Two", "Loadscene");
        break;
      case 2:
        sendMessage("Scene Three", "Loadscene");
        break;
      default:
        break;
    }
  }, [sendMessage, sceneId]);

  /* FUNCTIONS RELATED TO SCENES*/

  // const DeleteScene: any = async (id: any) => {
  //   const response = await deleteScene(id);
  //   if (response.status === 200) {
  //     GetAllScenes();
  //   }
  // };

  useEffect(() => {
    if (unityLoaded && CurrentSceneData) {
      const data: any = { Id: CurrentSceneData.id, Data: CurrentSceneData.unityJson };
      sendMessage("__AssetsMapHandler", "LoadFromReact", JSON.stringify(data));
    }
  }, [unityLoaded, CurrentSceneData]);

  useEffect(() => {
    if (AllScenesModalOpen) {
      pauseUnity();
      GetAllScenes();
    } else {
      resumeUnity();
    }
  }, [AllScenesModalOpen]);

  const LoadSceneModal: any = () => {
    if (isLoaded) {
      pauseUnity();
      setAllScenesModalOpen(true);
    }
  };


  const handleCloseSceneListModal = () => {
    setAllScenesModalOpen(false);
  };

  const CloseSceneHandlerModal = () => {
    setSceneHandlerModalOpen(false);
    setEditSceneFormData(null);
  };

  const getSceneDetails = async () => {
    const response = await getScene();
    if (response.status === 200) {
      setEditSceneFormData(response.data);
    }
    setSceneHandlerModalOpen(true);
  };

  const currentSceneDataRef = useRef(CurrentSceneData);
  // const currentSceneIdRef = useRef(CurrentSceneId);
  useEffect(() => {
    currentSceneDataRef.current = CurrentSceneData;
    // currentSceneIdRef.current = CurrentSceneId;
  }, [CurrentSceneData]);

  const UpdateScene: any = useCallback(async (unityJson: string) => {
    if (currentSceneDataRef.current !== null) {
      const { name, desc } = currentSceneDataRef.current;
      setScenesData({
        name: name,
        desc: desc,
        unityJson: unityJson,
      });
      setisSceneModalOpen(true);
    }
  }, []);

  const getWidgetsInfo = async () => {
    const widgetsData: any = await getWidgetsData();
    const widgetsMeta: any = await getWidgetsMeta();
    if (widgetsData.status === 200) {
      sendMessage("MainWidget", "SetWidgetData", JSON.stringify(widgetsData.data));
    }
    if (widgetsMeta.status === 200) {
      sendMessage("MainWidget", "SetWidgetMeta", JSON.stringify(widgetsMeta.data));
    }
  };

  useEffect(() => {
    if (itemToDelete && itemToDelete.id !== null) {  // Check if itemToDelete and id are valid
      const removeItem = async () => {
        const itemsConfig: Record<string, (id: any) => Promise<any>> = {
          widget: deleteWidgetApi,
          bubble: deleteBubbleApi,
          label: deleteLabelApi,
          note: deleteNoteApi
        };
        const api = itemsConfig[itemToDelete.item];
        if (api) {
          try {
            const response = await api(itemToDelete.id);
            if (response.status === 200) {
              setItemToDelete({ item: '', id: null });
            } else {
              console.error(`${itemToDelete.item} deletion failed with status: ${response.status}`);
            }
          } catch (error) {
            console.error(`Error deleting ${itemToDelete.item}:`, error);
          }
        } else {
          console.warn(`No delete function found for item type: ${itemToDelete.item}`);
        }
      };

      removeItem();
      getAllWidgets();
    }
  }, [itemToDelete]);

  useEffect(() => {
    if (widgetPositionUpdate !== null) {
      const updateWidgetPositionFc = async () => {
        const response = await updateWidgetPositionApi(widgetPositionUpdate);
        if (response.status === 200) {
          setWidgetPositionUpdate(null);
        }
        else {
          console.error('widget not updated');
        }
      };
      updateWidgetPositionFc();
    }
  }, [widgetPositionUpdate]);

  useEffect(() => {
    if (unityLoaded) {
      sendMessage("MainWidget", "SetWidgetList", JSON.stringify(allWidgets));
      getWidgetsInfo();
    }
  }, [allWidgets, unityLoaded]);

  useEffect(() => {
    if (modalOpen || noteModalOpen || LabelModalOpen || bubbleModalOpen) {
      sendMessage("__ReactInputBlocker", "SetBlockState", 1);
    }
    else {
      setPreviewData({ uniqueId: '', fileName: '', data: '' });
      getWidgetsInfo();
      sendMessage("MainWidget", "SetWidgetList", JSON.stringify(allWidgets));
      sendMessage("__ReactInputBlocker", "SetBlockState", 0);
    }
  }, [modalOpen, noteModalOpen, LabelModalOpen, bubbleModalOpen, allWidgets]);

  const getWidgets = async () => {
    const response = await getAllWidgets();
    if (response.status === 200) {
      setAllWidgets(response.data);
    } else {
      console.error('Widgets were not updated');
    }
  };

  useEffect(() => {
    if (widgetModalMode === 'none') {
      setIsMovementDisabled(0);
      getWidgets();
      setSelectedWidget(null);
    } else {
      updateTagsData();
      setIsMovementDisabled(1);
    }
  }, [widgetModalMode]);

  useEffect(() => {
    sendMessage("__ReactInputBlocker", "SetBlockState", isMovementDisabled);
  }, [isMovementDisabled]);

  const newWidgetAdded: any = useCallback((x: number, y: number, z: number) => {
    setSelectedWidget(null);
    setWidgetCoordinates([x, y, z]);
    setWidgetModalMode('create');
  }, []);

  const updateWidgetPosition: any = useCallback((position: string) => {
    setWidgetPositionUpdate(JSON.parse(position));
  }, []);

  const deleteWidget: any = useCallback((id: string) => {
    setItemToDelete({ item: 'widget', id: Number(id) });
  }, []);

  const unityTagsRequest: any = useCallback((ids: any) => {
    setTagsIds(JSON.parse(ids));
  }, []);

  const unityExpressionsRequest: any = useCallback((ids: any) => {
    setExpressionsIds(JSON.parse(ids));
  }, []);

  const openWidget: any = useCallback((id: string) => {
    setSelectedWidget(Number(id));
  }, []);

  // const deleteBubble: any = useCallback((id: string) => {
  //   setItemToDelete({ item: 'bubble', id: Number(id) });
  // }, []);

  const deleteLabel: any = useCallback((id: string) => {
    setItemToDelete({ item: 'label', id: Number(id) });
  }, []);

  const useDataFetcher = (ids: any, messageType: any, fetchDataFn: any) => {
    useEffect(() => {
      if (ids.length > 0) {
        const fetchData = async () => {
          const response = await fetchDataFn(ids, pastDate);
          if (response.status === 200) {
            sendMessage("DataManager", messageType, JSON.stringify(response.data));
          }
        };
        fetchData();
        const intervalId = setInterval(fetchData, 60000);
        return () => clearInterval(intervalId);
      }
    }, [ids, fetchDataFn, messageType, pastDate, window]);
  };

  useDataFetcher(tagsIds, "SetTagsData", getTagData);
  useDataFetcher(expressionIds, "SetExpressionsData", getExpressionData);

  useEffect(() => {
    if (Loadmessage && unityLoaded) {
      setTimeout(() => {
        setLoadmessage(null);
      }, 3000);
    }
  }, [Loadmessage, unityLoaded]);

  useEffect(() => {
    if (SceneHandlerModalOpen) {
      sendMessage("__ReactInputBlocker", "SetBlockState", 1);
    }
    else {
      sendMessage("__ReactInputBlocker", "SetBlockState", 0);
    }
  }, [SceneHandlerModalOpen]);

  useEffect(
    function () {
      addEventListener("UnityIsReady", unityReadyHandler);
      addEventListener("NewWidgetAdded", newWidgetAdded);
      addEventListener("UpdateWidgetPosition", updateWidgetPosition);
      addEventListener("DeleteWidget", deleteWidget);
      addEventListener("UnityTagsRequest", unityTagsRequest);
      addEventListener("UnityExpressionsRequest", unityExpressionsRequest);
      addEventListener("OpenWidget", openWidget);
      // addEventListener("DeleteBubble", deleteBubble);
      addEventListener("DeleteLabel", deleteLabel);
      addEventListener("SaveAssetsData", UpdateScene);
      addEventListener("LoadAssetsData", LoadSceneModal);

      addEventListener("TriggerOnLeftClick", tagsAndBubbleConfigHandler);
      addEventListener("TriggerOnRightClick", expressionsHandler);
      addEventListener("PlayerState", handlePlayerState);
      addEventListener("makeLogOut", logOut);

      addEventListener("NewBubbleAdded", createBubble);
      addEventListener("UpdateBubblePosition", updateBubblePosition);
      addEventListener("NewNoteAdded", createNote);
      addEventListener("UpdateNotePosition", updateNotePosition);
      addEventListener("openNote", openNote);
      addEventListener("R_OnChangeCameraType", handleTogglePins);
      addEventListener("NewLabelAdded", createLabel);
      addEventListener("openLabel", openLabel);
      addEventListener("UpdateLabelPosition", updateLabelPosition);
      addEventListener("unityProgressBar", unityProgressBarHandler);
      return () => {
        removeEventListener("UnityIsReady", unityReadyHandler);
        removeEventListener("NewWidgetAdded", newWidgetAdded);
        removeEventListener("UpdateWidgetPosition", updateWidgetPosition);
        removeEventListener("DeleteWidget", deleteWidget);
        removeEventListener("UnityTagsRequest", unityTagsRequest);
        removeEventListener("UnityExpressionsRequest", unityExpressionsRequest);
        removeEventListener("OpenWidget", openWidget);
        // removeEventListener("DeleteBubble", deleteBubble);
        removeEventListener("DeleteLabel", deleteLabel);
        removeEventListener("unityProgressBar", unityProgressBarHandler);
        removeEventListener("TriggerOnLeftClick", tagsAndBubbleConfigHandler);
        removeEventListener("TriggerOnRightClick", expressionsHandler);
        removeEventListener("PlayerState", handlePlayerState);
        removeEventListener("makeLogOut", logOut);
        removeEventListener("R_OnChangeCameraType", handleTogglePins);
        removeEventListener("NewBubbleAdded", createBubble);
        removeEventListener("NewNoteAdded", createNote);
        removeEventListener("NewLabelAdded", createLabel);
        removeEventListener("openLabel", openLabel);
        removeEventListener("UpdateLabelPosition", updateLabelPosition);
        removeEventListener("SaveAssetsData", UpdateScene);
        removeEventListener("LoadAssetsData", LoadSceneModal);
        // removeEventListener("RemoveAssetsData", DeleteScene);
      };
      // eslint-disable-next-line
    },
    [tagsAndBubbleConfigHandler, expressionsHandler, unityProgressBarHandler, isLoaded, updateScene]
  );

  useEffect(
    function () {
      if (unityLoaded) {
        unityDataHandler(fetchLatestData);
      }
    },
    [unityLoaded, fetchLatestData]
  );

  useEffect(
    function () {
      if (unityLoaded) {
        sendMessage("UserDetails", "setUserDetails", JSON.stringify(user));
      }
    },
    [unityLoaded]
  );

  /* resume unity when closing the modal/popup */
  const resumeUnity = () => {
    sendMessage("__ReactInputBlocker", "SetBlockState", 0);
  };

  const pauseUnity = () => {
    sendMessage("__ReactInputBlocker", "SetBlockState", 1);
  };

  useEffect(() => {
    if (!modalOpen) {
      resumeUnity();
    }
  }, [modalOpen]);

  useEffect(() => {
    if (widgetForBubbleId) {
      setChartType('widget');
    }
  }, [widgetForBubbleId]);

  // useEffect(() => {
  //   if (noteModalOpen) {
  //     sendMessage("Utilities", "PauseGameFromReact");
  //   } else {
  //     sendMessage("Utilities", "ResumeGame");
  //   }
  // }, [noteModalOpen]);

  const handleCloseModal = () => {
    resumeUnity();
    setModalOpen(false);
    setSceneHandlerModalOpen(false);
    setBubbleId(null);
  };

  const handleModalLoader = () => {
    setModalLoader(false);
  };

  const ticketTagsHandler = useCallback((tagId: number) => {
    updateTicketTags((state) => {
      const newList = [...state];
      newList.push(tagId);
      return newList;
    });
  }, []);

  const LabelCloseHandler = () => {
    setLabelModalOpen(false);
    resumeUnity();
  };

  // useEffect(() => {
  //   if (unityLoaded) {
  //     if (profileModal) {
  //       pauseUnity();
  //     } else {
  //       resumeUnity();
  //     }
  //   }
  // }, [unityLoaded, profileModal, pauseUnity, resumeUnity]);

  const bubbleCloseHandler = () => {
    setBubbleModalOpen(false);
    setBubblePopUp(null);
    resumeUnity();
    setbubbleAllDetails(null);
  };

  const noteCloseHandler = () => {
    setNoteModalOpen(false);
    resumeUnity();
  };

  const togglePins = () => {
    setShowPins((prev) => !prev);
  };

  return (
    <WebGLContext.Provider
      value={{
        resumeUnity,
        pauseUnity,
        chartData,
        bubbleDetails,
        handleModalLoader,
        handleCloseModal,
        modalOpen,
        modalLoader,
        ticketTags,
        ticketTagsHandler,
        popupBubbleId,
        configureBubbleHandler,
        chartType,
        setChartType,
        refreshUnityData,
        bubbleAllDetails
      }}
    >
      {modalOpen && chartData && <ChartsContainer setModalOpen={setModalOpen} WebGLContext={WebGLContext} chartType={chartType} unityChartsContainerConfig={unityChartsContainerConfig} hooks={allHooks} handleConfigClick={handleConfigClick} />}

      {AllScenesModalOpen && AllScenesList && <SceneList handleCloseModal={handleCloseSceneListModal} setSceneHandlerModalOpen={setSceneHandlerModalOpen} getSceneDetails={getSceneDetails} />}

      {/* {bubbleModalOpen && (
        <Bubble
          bubbleId={popupBubbleId}
          bubbleDetails={bubblePopUp}
          closeHandler={bubbleCloseHandler}
          updateBubbles={refreshUnityData}
          units={units}
          equipments={equipments}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
          selectedUnit={selectedUnit}
          bubbleConfig={bubbleConfig}
        />
      )} */}

      {widgetModalMode !== 'none' && <Widget
        units={units}
        equipments={equipments}
        widgetData={widgetData}
        setWidgetData={setWidgetData}
        widgetModalMode={widgetModalMode}
        setWidgetModalMode={setWidgetModalMode}
        unityWidgetConfig={unityWidgetConfig}
        hooks={allHooks}
      />}

      {noteModalOpen && (
        <Note
          noteId={popupNoteId}
          onFileClick={onFileClick}
          noteDetails={notePopup}
          closeHandler={noteCloseHandler}
          updateNotes={updateNotes}
          pauseUnity={pauseUnity}
          noteFormConfig={noteFormConfig}
          hooks={allHooks}
        />
      )}

      {Loadmessage && <div className="z-[9999] text-xl absolute  h-7 w-fit px-2 left-[40%] top-[45%] rounded-md bg-green bg-opacity-50 flex">{Loadmessage}<FiCheckCircle className="ml-2 mt-1" /></div>}

      {LabelModalOpen && (
        <Label
          LabelId={popupLabelId}
          LabelDetails={LabelPopup}
          closeHandler={LabelCloseHandler}
          updateLabels={updateLabels}
          pauseUnity={pauseUnity}
          resumeUnity={resumeUnity}
          allLabels={allLabels}
          LabelConfig={labelConfig}
        />
      )}

      {SceneHandlerModalOpen && (
        <AddSceneForm closeHandler={CloseSceneHandlerModal} pauseUnity={pauseUnity} />
      )}


      {showPins && unityPins && unityPins.length > 0 && (
        <div className="absolute right-0 2xl:right-2 bottom-0 2xl:bottom-3 rounded-md 3xl:p-3 p-1 flex flex-col gap-1 2xl:gap-3">
          {unityPins.slice(0, 6).map((pin: any) => (
            <Pin key={pin.id} data={pin} unityPin hooks={allHooks} />
          ))}
          <div className="flex items-end justify-between h-max w-full">
            <button className="btn" onClick={togglePins}>Hide Pins</button>
            {!unpinConfirmation && <button className="btn-danger" onClick={() => setUnpinConfirmation(true)}>Unpin All Tags</button>}
            {unpinConfirmation &&
              <div className="flex gap-5">
                <button className="btn-sec" onClick={() => setUnpinConfirmation(false)}>Keep</button>
                <button className="btn-danger" onClick={() => {
                  unpinAllWidgets(2);
                  setUnpinConfirmation(false);
                }}>Remove</button>
              </div>}
          </div>
        </div>
      )}

      {!showPins && unityPins && unityPins.length > 0 && (
        <div className="absolute right-10 2xl:right-2 bottom-0 2xl:bottom-3 rounded-md 3xl:p-3 p-1 flex flex-col gap-1 2xl:gap-3 w-[18.5vw]">
          <div className="flex items-end justify-start h-max w-full">
            <button className="btn" onClick={togglePins}>Show Pins</button>
          </div>
        </div>
      )}

      {previewData.uniqueId !== '' &&
        <div className="top-1/2 w-full h-auto opacity-95 rounded-md z-30 absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 sm:max-w-[1250px] max-h-[80vh]">
          <PreviewFiles
            data={previewData.data}
            fileName={previewData.fileName}
            type='unity'
            fileType={getFileExtension(previewData.fileName)}
            isModalOpen={modalOpen}
            setIsModalOpen={setModalOpen}
            onLeftArrowClick={() => null}
            onRightArrowClick={() => null}
          />
        </div>
      }

      <div
        id="unity-container"
        className={`w-full h-full`}
      >
        <SplashScreen
          open={!unityLoaded || showLogo}
          logo={true}
          status={loadingStatus}
        />
        <Unity
          unityProvider={unityProvider}
          className="unityApp"
          style={{
            width: "100%",
            height: "100%",
            visibility: unityLoaded || showLogo ? "visible" : "hidden",
          }}
          devicePixelRatio={isScaled ? 1.308 : 1}
        />
      </div>
      {isSceneModalOpen &&
        <div className="fixed inset-0 z-10 w-full h-screen">
          <div
            onClick={handleModalClose}
            className="h-full absolute z-20 inset-0 w-full flex items-center justify-center"
          >
            {/* Modal content */}
            <div
              className="w-max h-max bg-dark p-4 rounded-lg opacity-75 relative"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-center items-center gap-5 z-20">
                <div className="flex justify-center items-center flex-col gap-10 p-3 text-center">
                  <p>Are you sure you want to update the scene?</p>
                  {isLoadingProcess && <SmallLoader />}
                  <div className="flex gap-5 z-50">
                    <button
                      className="btn cursor-pointer"
                      onClick={handleScenesSubmit}
                    >
                      Update
                    </button>
                    <button
                      className="btn-sec cursor-pointer"
                      onClick={handleModalClose}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </WebGLContext.Provider>
  );
};

export default WebGL;