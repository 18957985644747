import { energyApi as api } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getPointCloudImages = (id: number) => {
  const url = `/PointCloud/GetPointCloudImages${extractedPath}?pointCloudId=${id}`;
  return api.get(url);
};

export const processDroneImages = () => {
  return api.get(`/PointCloud/ProcessDroneImages${extractedPath}`);
};