const extractUUIDs = (url: string) => {
  const uuidRegex = /\/o\/([a-f0-9-]+)\/s\/([a-f0-9-]+)/;
  const match = url.match(uuidRegex);

  if (match) {
    return {
      currOrg: match[1],
      curSol: match[2],
    };
  } else {
    throw new Error('Invalid URL format');
  }
};

export default extractUUIDs;