import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getAllPointClouds = () => {
  return energyApi.get(`/PointCloud/GetPointClouds${extractedPath}`);
};

export const getPointCloudbyId = (id: any) => {
  return energyApi.get(`/PointCloud/GetPointCloud${extractedPath}` + `?type=${id}`);
};

export const deletePointCloud = (id:any) => {
  return energyApi.get(`/PointCloud/DeletePointCloud${extractedPath}`);
};

export const modifyPointCloud = (data:any) => {
  return energyApi.post(`/PointCloud/ModifyPointCloud${extractedPath}`,data);
};
