import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";  // BrowserRouter is here
import App from "./App";
import { AppProvider } from "hooks/useApp";
import { DataProvider } from "hooks/useData";
import { ContextProvider } from "utilities/ContextProvider";
import { AuthProvider } from "react-oidc-context";
import { WebStorageStateStore } from "oidc-client-ts";
import { ScreenProvider } from "hooks/useScreen";
import { UnityProvider } from "hooks/UseUnity";
import { TabProvider } from "hooks/useAssetsActiveTab";
import { SceneProvider } from "hooks/useScene";
import { getExtractedPath, setExtractedPath } from "utilities/extractedPath";

setExtractedPath();

// Extract the base path
const baseUrl = getExtractedPath();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const oidcConfig = {
  client_id: `${process.env.REACT_APP_CLIENT_ID}`,
  client_secret: `${process.env.REACT_APP_CLIENT_SECRET}`,
  authority: `${process.env.REACT_APP_AUTH_APP}`,
  redirect_uri: `${process.env.REACT_APP_SAAS_APP}`,
  post_logout_redirect_uri: `${process.env.REACT_APP_SAAS_APP}`,
  scope: "openid",
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  onSigninCallback: (user: any) => {
    window.history.replaceState(
      {},
      window.document.title,
      window.location.origin + window.location.pathname
    );
    window.location = user.state || "/";
  },
};

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig}>
      <AppProvider>
        <SceneProvider>
          <UnityProvider>
            <TabProvider>
              <ScreenProvider>
                <DataProvider>
                  <ContextProvider>
                    <BrowserRouter basename={baseUrl}>
                      <App />
                    </BrowserRouter>
                  </ContextProvider>
                </DataProvider>
              </ScreenProvider>
            </TabProvider>
          </UnityProvider>
        </SceneProvider>
      </AppProvider>
    </AuthProvider>
  </React.StrictMode>
);

