/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { AlertHistoryProps } from "../types/alarmtypes";
import { alarmsAPI } from "./types/apiTypes";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const updateAlarm = (tagId: number, status: number) => {
  return energyApi.get(`/AlertTag/SetAlertStatus${extractedPath}?tagId=${tagId}&status=${status}`);
};

export const getAlarms = (): Promise<alarmsAPI> => {
  return energyApi.get(`/AlertTag/GetAlertTagHistories${extractedPath}`);
};

export const updateAlarmHistory = (alarmId: number, status: number) => {
  return energyApi.get(
    `/AlertTag/SetAlertHistoryStatus${extractedPath}?tagHistoryId=${alarmId}&status=${status}`
  );
};

export const deleteAlarm = (alarmId: number) => {
  return energyApi.get(`/AlertTag/DeleteAlertHistory${extractedPath}?tagHistoryId=${alarmId}`);
};

export const getCount = (): Promise<number> => {
  return energyApi.get(`/AlertTag/GetActiveAlertCount${extractedPath}`);
};

export const deleteAllAlarm = () => {
  return energyApi.get(`/AlertTag/DeleteAllAlerts${extractedPath}`);
};

export const acceptAllAlarm = () => {
  return energyApi.get(`/AlertTag/AcceptAllAlerts${extractedPath}`);
};

