import BrainImg from "components/shared/BrainImg";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import classNames from "utilities/ClassNames";
import { appear } from "./config/appear";
import { slide } from "./config/slide";
import { SideMenuProps } from "./domain/types";

const SideMenu: React.FC<SideMenuProps> = ({ closeMenu, showSideMenu, navLinks, hooks }) => {
  const { useApp, useScreen } = hooks;
  const { updateDashboardWidgets, dashboardWidgets, userRole } = useApp();
  const { isScaled } = useScreen();
  const { pathname } = useLocation();
  const navigate = useNavigate(); // Use navigate from react-router-dom
  const [shouldRefresh, setShouldRefresh] = useState(false);

  const handleNavLinkClick = (targetPath: string) => {
    if (pathname === "/3d-dashboard") {
      setShouldRefresh(true);
    }
    navigate(targetPath);
  };

  useEffect(() => {
    if (shouldRefresh) {
      setShouldRefresh(false);
      navigate("/"); 
    }
  }, [shouldRefresh, navigate]);

  return (
    <motion.div
      initial="exit"
      animate={showSideMenu ? "enter" : "exit"}
      variants={appear}
      className="fixed z-40 inset-0 w-full bg-black/90"
      onClick={closeMenu}
    >
      <motion.div
        initial="exit"
        animate={showSideMenu ? "enter" : "exit"}
        variants={slide}
        className={classNames(
          "xl-2xl:w-[330px] 2xl:w-[350px]",
          "absolute h-full left-0 top-0 z-20 bg-gray"
        )}
      >
        <div className="h-full w-full mt-20 pb-20 flex flex-col justify-between">
          <ul className="flex flex-col">
            {navLinks(isScaled).map((navLink: any, index: number) =>
              !(
                (userRole !== "admin" && navLink.text === "Logs") ||
                (pathname !== "/dashboard" && navLink.text === "Dashboard Configuration")
              ) && (
                <NavLink
                  key={index}
                  to={navLink.to}
                  onClick={() => {
                    if (navLink.text === "Dashboard Configuration") {
                      closeMenu();
                      updateDashboardWidgets({
                        ...dashboardWidgets,
                        selectedType: "init",
                      });
                    } else {
                      handleNavLinkClick(navLink.to); // Use handleNavLinkClick for navigation
                    }
                  }}
                  className={({ isActive }) => {
                    const baseClasses = `flex items-center gap-5 w-full border-b border-b-slate last:border-b--0 bg-transparent ${isScaled ? "p-1.5" : "p-3"}`;
                    const activeClasses = `bg-gradient-to-r from-green to-blue font-bold`;
                    const hoverClasses = `hover:bg-black duration-300`;

                    return navLink.text === "Dashboard Configuration"
                      ? `${baseClasses} ${hoverClasses}`
                      : isActive
                        ? `${baseClasses} ${activeClasses}`
                        : `${baseClasses} ${hoverClasses}`;
                  }}
                >
                  {navLink.icon}
                  <span className={"2xl:text-base xl-2xl:text-sm xl:text-xs"}>
                    {navLink.text}
                  </span>
                </NavLink>
              )
            )}
          </ul>
          <div className="grid place-content-center">
            <BrainImg />
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default SideMenu;
