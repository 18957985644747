/* eslint-disable @typescript-eslint/no-unused-expressions */
import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getUserTickets = async () => {
  return energyApi.get(`/ticket/getmyticket${extractedPath}`);
};

export const getTickets = async () => {
  return energyApi.get(`/ticket/getallticket${extractedPath}`);
};

export const createTicket = async (data: any) => {
  return energyApi.post(`/ticket/createticket${extractedPath}`, data);
};

export const closeTicket = (data: any) => {
  return energyApi.post(`/ticket/closeticket${extractedPath}` + `?id=${data}`);
};

export const rejectTicket = (data: any) => {
  return energyApi.post(`/ticket/rejectticket${extractedPath}` + `?id=${data}`);
};
export const deleteTicket = (data: any) => {
  return energyApi.post(`/ticket/deleteticket${extractedPath}` + `?id=${data}`);
};
