import logo from "assets/logo.svg";
import { useEffect, useState } from "react";
import { FiArrowLeft, FiMenu } from "react-icons/fi";
import { Link } from "react-router-dom";
import { Info, DateTime, BorderGradient } from "processhub-components-lib";
import { UserMenu } from "processhub-components-lib";
import SideMenu from "./SideMenu";
import useScreen from "hooks/useScreen";
import classNames from "utilities/ClassNames";
import { ReactComponent as HealthsensorIcon } from "assets/healthsensorIcon.svg";
import { useNavigate } from "react-router-dom";
import allHooks from "hooks";
import { profileConfig } from "./config/userMenuConfig";
import { navLinks } from "./config/navLinksConfig";
import { useAuth } from "react-oidc-context";
import { getExtractedPath } from "utilities/extractedPath";
import { getCurrentSolution } from "api/solutions";
import { motion } from "framer-motion";
import { slideProjectName } from "./config/animations";
import extractUUIDs from "./utils/extractUUIDs";

export default function Header({ setShowProjects }: any) {
  const [showSideMenu, setShowMenu] = useState(false);
  const [projectName, setProjectName] = useState('');
  const { isScaled } = useScreen();
  const navigate = useNavigate();
  const auth = useAuth();
  const logout = async () => auth.signoutRedirect();
  const baseUrl = getExtractedPath();
  const { currOrg, curSol } = extractUUIDs(baseUrl);

  const getProjectInfo = async () => {
    const response = await getCurrentSolution(currOrg, curSol);
    if (response.status === 200) {
      return response.data.data.name;
    }
    return '';
  };

  useEffect(() => {
    const fetchProjectName = async () => {
      const userProjectName = await getProjectInfo();
      setProjectName(userProjectName);
    };

    fetchProjectName();
  }, []);

  return (
    <div className="w-full bg-dark relative z-40">
      <div
        className={classNames(
          "lg:h-12 xl-2xl:h-14 2xl:h-16",
          "mx-auto flex items-center px-3"
        )}
      >
        <div className="relative z-50 flex items-center justify-center gap-5">
          <div
            onClick={() => setShowMenu(!showSideMenu)}
            className="cursor-pointer duration-300"
          >
            {!showSideMenu && <FiMenu size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
            {showSideMenu && <FiArrowLeft size={"30"} className="lg:w-[80%] xl-2xl:w-[90%] 2xl:w-full" />}
          </div>
          <Link to="/">
            <img
              src={logo}
              className={classNames("lg:h-6 xl-2xl:h-7 2xl:h-8")}
              alt="logo"
            />
          </Link>
          {/* Animated project name */}
          <motion.div
            initial="hidden"
            animate={showSideMenu ? "visible" : "hidden"}
            variants={slideProjectName}
            className="mt-1"
          >
            {projectName && (
              <span className="text-xl font-bold">
                {projectName}
              </span>
            )}
          </motion.div>
        </div>
        <div className="flex-1" />
        <div className="flex items-center">
          <button onClick={() => setShowProjects(true)}>
            <div className={classNames("lg:h-[2.15rem] 2xl:h-[2.65rem] w-full", "rounded-lg bg-gradient-to-r from-green to-blue items-center justify-center")}>
              <button className={classNames("h-[2.40rem] w-40 text-xl lg:h-[1.95rem] 2xl:h-[2.40rem] lg:w-32 2xl:w-40 lg:text-sm 2xl:text-lg", "text-center bg-gradient-to-r from-gray to-black rounded-lg hover:opacity-70 text-white mt-0.5 mb-0.5 ml-0.5 mr-0.5")}>
                Projects List
              </button>
            </div>
          </button>
          <DateTime hooks={allHooks} />
          <div className="hover:text-green cursor-pointer w-max mr-6" onClick={() => navigate('/health')}>
            <HealthsensorIcon className={classNames(isScaled ? "h-[19px] w-[19px]" : "h-[23px] w-[23px] border-white rounded-full hover:text-green")} />
          </div>
          <Info hooks={allHooks} />
          <UserMenu profileConfig={profileConfig} hooks={allHooks} logout={logout} />
          <SideMenu
            closeMenu={() => setShowMenu(false)}
            showSideMenu={showSideMenu}
            navLinks={navLinks}
            hooks={allHooks}
          />
        </div>
      </div>
    </div>
  );
}

