import { energyApi } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getAllHealth = () => {
  return energyApi.get(`/Health/GetAll${extractedPath}`);
};

export const refreshHealth = (id: any) => {
  return energyApi.get(`/Health/Get${extractedPath}` + `?type=${id}`);
};
