import { energyApi as api } from "utilities/Axios";
import { getExtractedPath } from "utilities/extractedPath";

const extractedPath = getExtractedPath();

export const getAllProjects = () => {
  const url = `/ProjectPlan/GetAllProjects${extractedPath}`;
  return api.get(url);
};

export const addProject = (id: string) => {
  const url = `/ProjectPlan/ProcessProjectFile${extractedPath}?fileId=${id}`;
  return api.get(url);
};


export const getAllTasks = (id: string) => {
  const url = `/ProjectPlan/GetAllTask${extractedPath}?projectId=${id}`;
  return api.get(url);
};